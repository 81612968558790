import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/seo"
import HeroView from "../components/HeroView"
import Steps from "../components/Steps"
import SectionFirst from "../components/SectionFirst"
import WhyUs from "../components/WhyUs"
import OurCars from "../components/OurCars"
import Specializations from "../components/Specializations"
import Opinions from "../components/Opinions"
import Contact from "../components/Contact"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Clios" />
    <HeroView />
    <Steps />
    <SectionFirst />
    <WhyUs />
    <OurCars />
    <Specializations />
    <Opinions />
    <Contact />
  </Layout>
)

export default IndexPage
