import React from "react"
import styled from "styled-components"

const StyledCard = styled.div`
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(58, 70, 91, 0.1),
    0 2px 4px 0 rgba(6, 41, 100, 0.1);
  background-color: #fff;
  margin-top: 16px;
  padding: 20px;
  @media (min-width: 1024px) {
    margin-top: 0;
  }
`

const Header = styled.div`
  display: flex;
  margin-bottom: 8px;
  & img {
    max-width: 29px;
    margin-right: 12px;
  }
  & h3 {
    font-weight: 900;
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #062964;
  }
`

const Desc = styled.p`
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.25px;
  color: #3a465b;
`

const Card = ({ title, desc, img }) => {
  return (
    <StyledCard>
      <Header>
        <img src={img} alt="icon" />
        <h3>{title}</h3>
      </Header>
      <Desc>{desc}</Desc>
    </StyledCard>
  )
}

export default Card
