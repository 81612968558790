import React from "react"
import styled from "styled-components"
import { StyledCard, Info, Model } from "./CarCard"
import Background from "gatsby-background-image"

const ExtendStyledCard = styled(StyledCard)`
  justify-content: space-between;
  @media (min-width: 1024px) {
    height: auto;
    justify-content: flex-start;
  }
`

const ExtendInfo = styled(Info)`
  @media (min-width: 1024px) {
    &::before {
      transform: translateY(-35px);
    }
  }
`

const Image = styled(Background)`
  height: 100%;
  width: 100%;
  position: relative;
  @media (min-width: 1024px) {
    height: 180px;
    margin-bottom: 15px;
  }
`

const Desc = styled.p`
  text-align: center;
  color: #3a465b;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.3px;
  margin-top: 8px;
  padding: 0 16px;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.25px;
  }
`

const ExtendModel = styled(Model)`
  font-size: 24px;
  letter-spacing: 0.3px;
  font-weight: 900;
`

const SpecializationCard = ({ img, title, desc }) => {
  return (
    <ExtendStyledCard>
      <Image fluid={img.childImageSharp.fluid} />
      <ExtendInfo>
        <ExtendModel>{title}</ExtendModel>
        <Desc>{desc}</Desc>
      </ExtendInfo>
    </ExtendStyledCard>
  )
}

export default SpecializationCard
