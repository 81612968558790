import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import Slider from "react-slick"
import { StyledButton } from "./styledButton"
import Title from "./Title"
import CarCard, { StyledCard } from "./CarCard"

const StyledSection = styled.section`
  padding: 48px 16px;
  @media (min-width: 768px) {
    max-width: 576px;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    max-width: 100%;
    padding: 120px 16px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr 1fr;
  }
  @media (min-width: 1200px) {
    grid-column-gap: 50px;
    max-width: 1200px;
  }
`

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.3px;
  color: #3a465b;
  & strong {
    font-weight: 900;
  }
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 32px;
  }
`

const ExtendStyledButton = styled(StyledButton)`
  box-shadow: 0 8px 16px 0 rgba(58, 70, 91, 0.1),
    0 2px 4px 0 rgba(6, 41, 100, 0.1);
  margin-top: 16px;
  width: -webkit-fill-available;
  text-align: center;
  text-decoration: none;
  font-weight: 900;
  @media (min-width: 1024px) {
    width: fit-content;
    margin-top: 20px;
    padding: 13px 27px;
  }
`

const Cars = styled.div`
  margin: 16px 0;
  .slick-slide {
    margin-right: 16px;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    &.slick-active {
      opacity: 1;
    }
  }
  ${StyledCard} {
    width: 242px;
  }
  @media (min-width: 1024px) {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    .slick-slide {
      margin-right: 30px;
    }
    .slick-list {
      padding: 20px;
      width: 600px;
    }
    .slick-track {
      padding: 20px;
    }
  }
  @media (min-width: 1200px) {
    ${StyledCard} {
      width: 270px;
      height: 340px;
    }
  }
`
const FirstBox = styled.div`
  & ${Paragraph} {
    margin-top: 16px;
  }
  @media (min-width: 1024px) {
    width: 90%;
    & ${Paragraph} {
      margin-top: 10px;
    }
  }
`

const SecondBox = styled.div`
  @media (min-width: 1024px) {
    margin-top: 30px;
    width: 90%;
  }
`

const OurCars = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
  }

  const { mustang, camaro, arteon } = useStaticQuery(graphql`
    query {
      mustang: file(relativePath: { eq: "flota/mustang.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      camaro: file(relativePath: { eq: "flota/camaro.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      arteon: file(relativePath: { eq: "flota/arteon.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <FirstBox>
        <Title>
          <span>Flota samochodów zastępczych</span>, dostępnych dla Ciebie.
        </Title>
        <Paragraph>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
          doloremque ratione iste aut autem consequatur culpa reiciendis vitae
          modi dolores.
        </Paragraph>
      </FirstBox>
      <Cars>
        <Slider {...settings}>
          <CarCard name="Ford Mustang" img={mustang} />
          <CarCard name="Chevrolet Camaro" img={camaro} />
          <CarCard name="Volkswagen Arteon" img={arteon} />
        </Slider>
      </Cars>
      <SecondBox>
        <Paragraph>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum, ex? Dej
          no te auto
        </Paragraph>
        <ExtendStyledButton as={Link} to="/flota/">
          Zobacz flotę
        </ExtendStyledButton>
      </SecondBox>
    </StyledSection>
  )
}

export default OurCars
