import React from "react"
import styled from "styled-components"
import Quote from "../images/quote/quote.inline.svg"

const StyledOpinion = styled.p`
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 32px;
  color: #3a465b;
  margin-top: 16px;
  @media (min-width: 1024px) {
    font-size: 20px;
    margin-top: 20px;
  }
`

const Name = styled.p`
  font-size: 18px;
  font-style: italic;
  letter-spacing: 0.3px;
  color: #3a465b;
  margin-top: 16px;
  font-weight: 700;
  @media (min-width: 1024px) {
    font-size: 20px;
    margin-top: 20px;
  }
`

const StyledCard = styled.div`
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(58, 70, 91, 0.1),
    0 2px 4px 0 rgba(6, 41, 100, 0.1);
  background-color: rgba(255, 255, 255, 0.9);
  transform: translateX(-15px);
  margin: 0 15px;
  transition: all 0.2s ease;
  cursor: pointer;
  width: calc(100% - 48px);
  @media (min-width: 1023px) {
    margin: 0;
    transform: translateX(0);
    padding: 40px 30px;
  }
  &:hover {
    background-color: #196fd9;
    #background-quote {
      fill: #fff;
    }
    ${StyledOpinion}, ${Name} {
      color: #fff;
    }
  }
`

const QuoteBox = styled.div``

const Opinion = ({ opinion, name }) => {
  return (
    <StyledCard>
      <QuoteBox>
        <Quote />
      </QuoteBox>
      <StyledOpinion>{opinion}</StyledOpinion>
      <Name>{name}</Name>
    </StyledCard>
  )
}

export default Opinion
