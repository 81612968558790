import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Title from "./Title"
import SpecializationCard from "./SpecializationCard"
import { StyledCard } from "./CarCard"

const StyledSection = styled.section`
  padding: 0 16px;
  @media (min-width: 768px) {
    max-width: 576px;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    max-width: 1200px;
    padding: 0 16px 120px;
  }
`

const Box = styled.div`
  margin-top: 16px;
  & ${StyledCard} {
    margin-top: 16px;
  }
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
  }
`

const Specializations = () => {
  const { obrazenia, pojazd, kredyty } = useStaticQuery(graphql`
    query {
      obrazenia: file(
        relativePath: { eq: "specjalizacje/obrazeniaCiala.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pojazd: file(relativePath: { eq: "specjalizacje/uszkodzonyPojazd.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kredyty: file(relativePath: { eq: "specjalizacje/kredytyFrankowe.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <Title>
        <span>Nasze specjalizacje</span> w branży jakiejśtam
      </Title>
      <Box>
        <SpecializationCard
          title="Obrażenia ciała"
          desc="Lorem ipsum dolor sit amet consectetur adipisicing elit."
          img={obrazenia}
        />
        <SpecializationCard
          title="Dopłata do odszkodowania"
          desc="Lorem ipsum dolor sit amet consectetur adipisicing elit."
          img={pojazd}
        />
        <SpecializationCard
          title="Naprawa bezgotówkowa"
          desc="Lorem ipsum dolor sit amet consectetur adipisicing elit."
          img={kredyty}
        />
      </Box>
    </StyledSection>
  )
}

export default Specializations
