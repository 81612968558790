import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Slider from "react-slick"
import Title from "./Title"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Opinion from "./Opinion"

const StyledSection = styled.section`
  padding: 48px 16px 0;
  position: relative;
  @media (min-width: 768px) {
    max-width: 576px;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    max-width: 1200px;
  }
`
const StyledBgImg = styled(Img)`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    position: absolute !important;
    top: calc(50% + 48px);
    z-index: -1;
    right: -50%;
    width: 600px;
    height: 100%;
    transform: translate(-90%, -50%);
    border-radius: 4px;
  }
  @media (min-width: 1200px) {
    width: 805px;
    transform: translate(-60%, -50%);
  }
`

const GridPc = styled.div`
  display: none;
  @media (min-width: 1024px) {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
  }
`

const CustomDot = styled.div`
  width: 16px;
  height: 2px;
  background-color: #fff;
  opacity: 0.5;
  margin-top: 16px;
`

const Box = styled(BackgroundImage)`
  width: 100vw;
  margin-top: 46px;
  transform: translate(-16px);
  padding-bottom: 10px;
  @media (min-width: 768px) {
    transform: translate(0);
  }
  @media (min-width: 1023px) {
    display: none;
  }
  .slick-slider {
    margin-left: 16px;
    transform: translateY(-30px);
    & .slick-dots {
      width: calc(100% - 16px);
    }
    & .slick-dots .slick-active ${CustomDot} {
      opacity: 1;
    }
  }
  @media (min-width: 768px) {
    max-width: 576px;
    margin: 46px auto 0;
  }
`

const Opinions = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    customPaging: () => <CustomDot />,
  }

  const { bg } = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "bgopinions.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <Title>
        <span>Opinie</span> naszych klientów
      </Title>
      <Box fluid={bg.childImageSharp.fluid}>
        <Slider {...settings}>
          <Opinion
            opinion="Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia nesciunt quisquam maiores cum quibusdam voluptates laborum doloremque qui, sunt dolorum nemo ipsa eos autem omnis ea quo, facere vitae et."
            name="Daniel P"
          />
          <Opinion
            opinion="Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia nesciunt quisquam maiores cum quibusdam voluptates laborum doloremque qui, sunt dolorum nemo ipsa eos autem omnis ea quo, facere vitae et."
            name="Jan B"
          />
          <Opinion
            opinion="Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia nesciunt quisquam maiores cum quibusdam voluptates laborum doloremque qui, sunt dolorum nemo ipsa eos autem omnis ea quo, facere vitae et."
            name="Ania M"
          />
        </Slider>
      </Box>
      <GridPc>
        <Opinion
          opinion="Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia nesciunt quisquam maiores cum quibusdam voluptates laborum doloremque qui, sunt dolorum nemo ipsa eos autem omnis ea quo, facere vitae et."
          name="Daniel P"
        />
        <Opinion
          opinion="Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia nesciunt quisquam maiores cum quibusdam voluptates laborum doloremque qui, sunt dolorum nemo ipsa eos autem omnis ea quo, facere vitae et."
          name="Jan B"
        />
        <Opinion
          opinion="Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia nesciunt quisquam maiores cum quibusdam voluptates laborum doloremque qui, sunt dolorum nemo ipsa eos autem omnis ea quo, facere vitae et."
          name="Ania M"
        />
      </GridPc>
      <StyledBgImg fluid={bg.childImageSharp.fluid} />
    </StyledSection>
  )
}

export default Opinions
