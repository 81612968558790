import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Title from "./Title"
import Card from "./whyusCards/Card"
import money from "../images/icons/whyus/money.svg"
import person from "../images/icons/whyus/person.svg"
import poland from "../images/icons/whyus/poland.svg"
import unlimited from "../images/icons/whyus/unlimited.svg"

const StyledSection = styled.section`
  padding: 0 16px;
  position: relative;
  @media (min-width: 768px) {
    max-width: 576px;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    max-width: 1200px;
  }
`
const Cards = styled.div`
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    margin-top: 40px;
  }
  @media (min-width: 1200px) {
    grid-column-gap: 30px;
  }
`

const StyledImg = styled(Img)`
  width: 100vw;
  transform: translateX(-16px);
  margin-top: -20px;
  position: relative;
  z-index: -1;
  @media (min-width: 768px) {
    width: 100%;
    transform: translateX(0);
  }
  @media (min-width: 1024px) {
    width: 100vw;
    transform: translateX(-16px);
    margin-top: -100px;
  }
  @media (min-width: 1200px) {
    margin: -150px auto 0;
    max-width: 1570px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`

const WhyUs = () => {
  const { audi } = useStaticQuery(graphql`
    query {
      audi: file(relativePath: { eq: "aboutusaudi.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <Title>
        <span>Dlaczego nasze</span> SDK jest najlepsze?
      </Title>
      <Cards>
        <Card
          title="Cały świat"
          desc="Lorem ipsum dolor sit amet consectetur."
          img={poland}
        />
        <Card
          title="Brak opłat"
          desc="Lorem ipsum dolor sit amet consectetur."
          img={money}
        />
        <Card
          title="Bez limitów"
          desc="Lorem ipsum dolor sit amet consectetur."
          img={unlimited}
        />
        <Card
          title="Każdego nagra"
          desc="Lorem ipsum dolor sit amet consectetur."
          img={person}
        />
      </Cards>
      <StyledImg fluid={audi.childImageSharp.fluid} alt="Tło" />
    </StyledSection>
  )
}

export default WhyUs
