import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import HeroForm from "./HeroForm"
import Advantages from "./Advantages"
import quote from "../images/quote/quote-hero.svg"
import dotsMobile from "../images/ui/dots-mobile.svg"
import callicon from "../images/icons/contact/call-icon.svg"
import { StyledButton } from "../components/styledButton"

const StyledSection = styled.section`
  width: 100vw;
  margin-top: 110px;
  min-height: 290px;
  @media (min-width: 768px) {
    max-width: 578px;
    margin: 110px auto 0;
  }
  @media (min-width: 1024px) {
    max-width: 992px;
    margin-top: 180px;
    display: flex;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
    justify-content: space-between;
  }
`

const Content = styled.div`
  padding: 0 16px;
  @media (min-width: 1024px) {
    max-width: 1200px;
  }
`

const Heading = styled.h1`
  color: #062964;
  font-size: 30px;
  letter-spacing: 1px;
  font-weight: 900;
  margin-bottom: 24px;
  line-height: 42px;
  position: relative;
  @media (min-width: 1024px) {
    font-size: 48px;
    line-height: 57px;
    letter-spacing: 1px;
    margin-bottom: 34px;
  }
`

const StyledSign = styled(Img)`
  min-height: 24px;
  width: 152px;
`

const SignedPhoto = styled.div`
  position: relative;
  width: 100%;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    max-width: 576px;
    margin: 64px auto 0;
  }
  @media (min-width: 1024px) {
    max-width: 480px;
    margin: 0 auto;
  }
  @media (min-width: 1200px) {
    max-width: 576px;
    margin: 0;
  }
`

const Container = styled.div`
  position: relative;
  height: 300px;
  overflow: hidden;
  @media (min-width: 1024px) {
    height: 380px;
  }
  & .dots {
    position: absolute;
    top: 42px;
    right: calc(85% + 10px);
    z-index: 10;
    @media (min-width: 1024px) {
      top: 50px;
      right: calc(85% + 18px);
    }
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 85%;
  min-height: 270px;
  max-height: 270px;
  @media (min-width: 1024px) {
    max-height: 100%;
    max-height: 100%;
  }
`

const StyledImg = styled(Img)`
  z-index: 5;
  max-height: 300px;
  @media (min-width: 1024px) {
    max-height: 100%;
  }
`

const Rectangle = styled.div`
  width: 400px;
  position: absolute;
  top: 32px;
  left: 0;
  background-color: #c7dbf5;
  height: 100%;
`

const Person = styled.div`
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(58, 70, 91, 0.1),
    0 2px 4px 0 rgba(6, 41, 100, 0.1);
  padding: 16px 16px 24px;
  position: relative;
  & img {
    position: absolute;
    top: 16px;
    left: 5%;
  }
  & .opinion-text {
    max-width: 240px;
    margin: 0 auto 12px;
    color: #3a465b;
  }
  & .person-box {
    display: flex;
    align-items: center;
    max-width: 240px;
    margin: 0 auto;
    & .box {
      margin-left: 12px;
    }
    & .photo {
      width: 40px;
      height: 40px;
      background-color: #d8d8d8;
      border-radius: 50%;
    }
    & .quote {
      margin-right: 12px;
      max-width: 40px;
    }
    & .job {
      color: #196fd9;
      font-size: 12px;
      letter-spacing: 0.3px;
    }
  }
  @media (min-width: 1200px) {
    max-width: 65%;
    padding: 20px 20px 24px;
    & .opinion-text {
      max-width: 260px;
      margin: 0 auto 16px;
    }
    & .person-box {
      max-width: 260px;
    }
  }
`

const Button = styled(StyledButton)`
  padding: 0;
  margin-left: 0;
  align-items: center;
  padding: 14px 40px !important;
  box-shadow: 0 8px 16px 0 rgba(58, 70, 91, 0.1),
    0 2px 4px 0 rgba(6, 41, 100, 0.1);
  color: #fff !important;
  width: fit-content;
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    margin-top: 24px;
    font-size: 16px !important;
  }
  & img {
    margin-right: 8px;
  }
  &.mobile-btn {
    margin-left: 0;
    margin-top: 48px;
    width: calc(100% - 80px);
    justify-content: center;
    display: flex;
    margin-bottom: 32px !important;
    position: relative;
    @media (min-width: 768px) {
      max-width: 576px;
      width: calc(100% - 80px);
    }
    @media (min-width: 1024px) {
      display: none;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      transform: translateY(32px);
    }
  }
`

const HeroView = () => {
  const { image, mati1, signDark } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "car-accident.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mati1: file(relativePath: { eq: "mati1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      signDark: file(relativePath: { eq: "mateusz-bednarzDark.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <StyledSection>
        <Content>
          <Heading>
            Lorem ipsum <br /> dolor sit amet.
          </Heading>
          <HeroForm />
          <Button as="a" href="tel:124234523">
            <img src={callicon} alt="zadzwoń do nas" /> Zadzwoń
          </Button>
          <Advantages />
          <Button as="a" href="tel:124234523" className="mobile-btn">
            <img src={callicon} alt="zadzwoń do nas" /> Zadzwoń
          </Button>
        </Content>
        <SignedPhoto>
          <Container>
            <ImageWrapper>
              <StyledImg
                fluid={image.childImageSharp.fluid}
                alt="Zdjęcie z wypadku"
              />
            </ImageWrapper>
            <Rectangle />
            <img src={dotsMobile} alt="Kropki" className="dots" />
          </Container>
          <Person>
            <img src={quote} alt="Cytat" className="quote" />
            <p className="opinion-text">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga,
              placeat? Adipisci illo maiores quisquam.
            </p>
            <div className="person-box">
              <Img fluid={mati1.childImageSharp.fluid} className="photo" />
              <div className="box">
                <StyledSign
                  fluid={signDark.childImageSharp.fluid}
                  objectFit="contain"
                />
                <p className="job">CEO lorem.pl</p>
              </div>
            </div>
          </Person>
        </SignedPhoto>
      </StyledSection>
    </>
  )
}

export default HeroView
